import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import Icon from "../components/icon";
import theme from "../styles/theme.module.scss";
import styles from "../styles/404.module.scss";

export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
    }
  }
`;

const NotFoundPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <h1 hidden>NOT FOUND</h1>
        <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT6}`}>
          <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.mB3}`}>
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
          <ul className={`${styles.subPagesLinks} ${theme.gdfull}`}>
            <li className={` ${theme.borderTop} ${theme.gdcont} ${theme.h2_founders}`}>
              <Link
                to="/work"
                className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont}`}
              >
                Discover our work <Icon className={`${styles.icon}`} symbol="arrowRight" />
              </Link>
            </li>
            <li className={`${theme.borderTop} ${theme.gdcont}  ${theme.h2_founders}`}>
              <Link
                to="/about"
                className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont}`}
              >
                Learn About Us <Icon className={`${styles.icon}`} symbol="arrowRight" />
              </Link>
            </li>
            <li
              className={` ${theme.borderTop} ${theme.gdcont} ${theme.borderBottom} ${theme.h2_founders}`}
            >
              <Link
                to="/contact"
                className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont}`}
              >
                Get In Touch <Icon className={`${styles.icon}`} symbol="arrowRight" />
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
